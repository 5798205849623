import { useState, useEffect } from "react"
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import { useForm } from "react-hook-form";
import { Button, CustomForm } from "./style"
import './style.css';
import { toast } from "react-toastify";
import { clientService } from "../../services";
import loader from "../../../src/loader.gif";
import TokenService from "../../services/token.service";
import {
    GetCountries,
} from "react-country-state-city";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };
export const Form = ({ entity }) => {
    const [refValue, setRefValue] = useState('33');
    const [userLogin, setUserLogin] = useState('');
    const [countryid, setCountryid] = useState(0);
    const [declaration, setDeclaration] = useState(false);
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(0);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [entityValue, setEntityValue] = useState('');
    const [utmMedium, setUtmMedium] = useState('');

    const [countryList, setCountriesList] = useState([]);

    const {
        setValue,
        reset,
    } = useForm();


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const entityParam = searchParams.get('entity');
        const utm = searchParams.get('utm_medium');

        if (entityParam && utm) {
            setEntityValue(entityParam);
            setUtmMedium(utm);
        }

    }, [])

    useEffect(() => {
        GetCountries().then((result) => {
            setCountriesList(result);
        });
        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        if (refParam) {
            console.log('Reference Value is ', refParam);
            setRefValue(refParam);
        }

        clientService.authorization().then((res) => {
            const token = res.token;
            const decryptToken = decryptData(token);
            TokenService.setUser(decryptToken);
        }).catch((err) => {
            console.log('Error for auth is ', err);
            toast.error("Authorization error!");
        });
    }, []);

    const [formData, setFormData] = useState({
        step1: { agentId: refValue, firstName: '', email: '', phone: '', password: '', country: '', entity: entityValue, utmMedium: utmMedium },
    });

    const data = {
        agentId: refValue,
        firstName: formData.step1.firstName,
        email: formData.step1.email,
        phone: formData.step1.phone,
        country: formData.step1.country,
        password: formData.step1.password,
        entity: entityValue,
        utmMedium: utmMedium,
    }
    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, phone: false, country: false, password: false,
        }
    });

    const formValueChange = (step, name, value) => {
        if (name === 'email') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                setEmailError(1); // Set email error state if the email is invalid
            } else {
                setEmailError(0); // Clear email error state if the email is valid
            }
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [step]: {
                ...prevFormData[step],
                [name]: value,
            },
        }));
        if (name === 'password' || name === 'confirmPassword') {
            if (formData.step1.password === formData.step1.confirmPassword) {
                setValidationErrors(false);
            }
            validatePassword(formData.step1.password);
        }
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{8,15}$/;
        const isValid = regex.test(password);

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                password: !isValid,
            },
        }));
    };

    const resetForm = () => {
        setFormData({
            step1: { agentId: '', firstName: '', lastName: '', email: '', phone: '', password: '', country: '' },
        });
    }
    const isStep1Valid = () => {
        const { firstName, email, phone, password, country } = formData.step1;

        const isfirstNameValid = firstName.trim() !== '';
        //const islastNameValid = lastName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isphoneValid = phone.trim() !== '';
        const isPasswordValid = password.trim() !== '';
        const isCountryValid = country !== '';
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isphoneValid,
                password: !isPasswordValid,
                country: !isCountryValid,
            },
        }));
        return isfirstNameValid && isEmailValid && isphoneValid && isPasswordValid && isCountryValid;
    };

    const submitForm = (e, action) => {
        e.preventDefault();
        setCheck(true);
        if (action === 'login') {
            window.location.href = "https://login.godocm.com";
        } else {
            const step1Data = { ...formData.step1, agentId: refValue || '33', entity: entityValue, utmMedium: utmMedium };
            if (isStep1Valid()) {
                setLoading(true);
                const token = TokenService.getUser();
                clientService.demoClient(step1Data, token)
                    .then((res) => {
                        console.log('created user is ', res);
                        if (res && res.data && res.data.message) {
                            toast.error(res.data.message); // Display the error message
                            setLoading(false);
                        } else {
                            clientService.authorizationCp(res.login.toString(), formData.step1.password).then((res) => {
                                // Once authorization is successful, get the token
                                const token = res.token;
                                const redirectURL = `https://login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;
                                window.location.href = redirectURL;
                            });
                            toast.success('Successfully Registered!');
                        }
                        
                    })
                    .catch((err) => {
                        toast.error("Error adding client");
                        setLoading(false);
                    });
            } else {
                setLoading(false);
                console.log('Issue in Step 2: Please fill in all required fields.');
                toast.error('Please Fill all Fields!');
                console.log('Form data:');
                console.log(step1Data);
            }
        }
    };

    return (
        <div>
            {loading ?
                <div id="global-loader1">
                    <img src={loader} className="loader-img" alt="Loading...." />
                </div> : <></>
            }
            <CustomForm style={{ backgroundImage: '../../background.jpg' }}>
                <div className="justify mt_100" style={{ width: '100%' }}>
                    <Row>
                        <Col md={4} style={{ display: 'none' }}>
                            <FormGroup>
                                <Label>Ibid</Label>
                                <div className="input-group">
                                    <Input className={"form-control"}
                                        name="agentId" type="text"
                                        required="" placeholder="John"
                                        value={refValue}
                                        onChange={(e) => formValueChange('step1', 'agentId', refValue)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Full Name ( as per your passport or national ID )</Label>
                                <div className="input-group">
                                    <Input className={"form-control " + (check && !formData.step1.firstName && 'is-invalid')}
                                        name="firstName" type="text"
                                        required="" placeholder="John"
                                        value={formData.step1.firstName}
                                        onChange={(e) => formValueChange('step1', 'firstName', e.target.value)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={4} style={{ display: 'none' }}>
                            <FormGroup>
                                <Label>Last Name</Label>
                                <div className="input-group">
                                    <Input className={"form-control " + (check && !formData.step1.lastName && 'is-invalid')}
                                        type="text"
                                        name="lastName"
                                        value={formData.step1.lastName}
                                        required="" placeholder="Doe"
                                        onChange={(e) => formValueChange('step1', 'lastName', e.target.value)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Email Address</Label>
                                <div className="input-group">
                                    <Input className={"form-control " + (check && !formData.step1.email && 'is-invalid')}
                                        type="email"
                                        name="email"
                                        validation={{ required: true }}
                                        value={formData.step1.email}
                                        invalid={emailError === 1 && formData.step1.email !== '' && 1 || validationErrors.step1.email}
                                        valid={emailError === 0 && formData.step1.email !== '' && 1 || !validationErrors.step1.email}
                                        placeholder="john@gmail.com"
                                        onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Country</Label>
                                <Input
                                    className={"mb-3 " + (check && !formData.step1.country && 'is-invalid')}
                                    type="select"
                                    name="country"
                                    onChange={(e) => {
                                        const selectedCountryName = e.target.value; // Get the selected country name
                                        const selectedCountry = countryList.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                        if (selectedCountry) {
                                            setCountryid(selectedCountry.iso2);
                                            setSelectedCountryName(selectedCountry?.name);
                                            setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                            console.log("Country Is ", selectedCountry);
                                            formValueChange('step1', 'country', selectedCountry.name);
                                        }
                                    }}
                                    value={countryList.find(country => country.iso2 === countryid)?.name || 'United Arab Emirates'}  // Set the value to the selected country name
                                >
                                    <option value="">-- Select --</option>
                                    {countryList.map((item, index) => (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row >



                        <Col md={6}>
                            <FormGroup>
                                <Label>Phone No</Label>
                                <div className="input-group">
                                    <IntlTelInput
                                        name="phone"
                                        containerClassName="intl-tel-input"
                                        inputClassName={"form-control " + (check && !formData.step1.phone && "is-invalid")}
                                        style={{ width: '100%' }}
                                        maxLength={15}
                                        format="true"
                                        defaultCountry='ae'
                                        useMobileFullscreenDropdown={false}
                                        value={formData.step1.phone} // Ensure the value is set correctly
                                        onSelectFlag={(selectedCountryData, fullNumber, isvalid, currentNumber) => {
                                            console.log('Selected Country:', selectedCountryData, fullNumber, currentNumber);
                                            setCountryid(fullNumber && fullNumber.iso2 && fullNumber.iso2.toUpperCase());
                                        }}
                                        onPhoneNumberChange={(status, value, countryData, number, id) => {
                                            console.log('Selected Country:', countryData?.name); // Log the selected country name
                                            formValueChange('step1', 'phone', value); // Update form data with the phone number value
                                            setSelectedCountryName(countryData?.name);
                                        }}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    className={"form-control required input_password " + (check && !formData.step1.password && 'is-invalid')}// + (!validationErrors.step1.password || formData.step1.password ? 'is-valid' : 'is-invalid')}
                                    placeholder="***********"
                                    invalid={validationErrors.step1.password}
                                    valid={!validationErrors.step1.password}
                                    value={formData.step1.password}
                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="justify text_center mt_50">
                        <Button onClick={(e) => submitForm(e, 'login')} className='primary-bg-color' style={{ height: '50px' }}>
                            {'Log In'}
                        </Button>
                        <Button type="submit" onClick={(e) => submitForm(e, 'submit')} className='primary-bg-color' style={{ height: '50px' }}>
                            {'Register'}
                        </Button>
                    </div>
                </div>
            </CustomForm>
        </div>
    )
}
